<template>
  <v-container
          fill-height
          fluid
          grid-list-xl
  >
    <v-layout
            justify-center
            class="overflow-x-auto"
            wrap
    >
      <template v-if="loaded">
        <data-table ref="datatable"
                    :api-end-point="apiurl"
                    :search="search"
                    :sort-order="sortOrder"
                    :table-name="'Clients'"
                    :isDetail="true"
                    :isExport="false"
                    track-by="id"
                    :pre-selected-filters="filters.selectedFilters"
        >
          <template v-slot:actions>
            <actions :actions="filters.actions" :delete-confirmation-message="'Client will be deleted and this action cannot be undone.'"></actions>
          </template>
          <template v-slot:filters>
            <filters :filters="filters.defaultFilters"></filters>
            <date-range-filter :filter-id="'client_registration_filter'"
                               :query-type="'Date'"
                               :column="'created_at'"
                               :operator="'between'"
            >
            </date-range-filter>
            <filters :filters="filters.clientStatus"></filters>
            <multi-select-filters class="mr-2"
                                  :filter-id="'utm_source_filter'"
                                  :filters="filters.clientSourceFilters"
                                  :relation-ship-name="'clientMarketingData'"
                                  :query-type="'relationship'"
                                  :placeholder="'Select source'"
                                  :column="'utm_source'"
                                  :operator="'in'"
            ></multi-select-filters>

            <multi-select-filters :filter-id="'utm_campaign_filter'"
                                  :filters="filters.clientCampaignFilters"
                                  :relation-ship-name="'clientMarketingData'"
                                  :query-type="'relationship'"
                                  :placeholder="'Select Campaign'"
                                  :column="'utm_campaign'"
                                  :operator="'in'"
            ></multi-select-filters>
          </template>
          <template v-slot:datatable-actions>
            <button class="btn btn-primary height-40 no-wrap mr-2" @click="showAddNewClientDialog = true;">Add Client</button>
          </template>
        </data-table>
      </template>

      <!-- delete dialog -->
      <confirmation v-if="deleteDialog" :visible="deleteDialog">
        <template slot="title">
          Are you sure?
        </template>
        <template slot="desc">
          This client will be removed. This action cannot be undone.
        </template>
        <template slot="actions">
          <a class="cancel-model" @click="hideConfirmation">Cancel</a>
          <button class="btn modal-action-button confirmation-delete" @click="removeClient">Delete</button>
        </template>
      </confirmation>

      <add-new-client v-if="showAddNewClientDialog"
                      :visible="showAddNewClientDialog"
                      v-on:hide="showAddNewClientDialog = false"
                      :organisations="organisations"
                      :plans="plans"
                      v-on:addNewClient="handleAddNewClient"
      />

    </v-layout>
  </v-container>
</template>

<script>
  import DataTable from "@/components/common/dataTable/DataTable";
  import Filters from "@/components/common/dataTable/filters/Filters";
  import MultiSelectFilters from "@/components/common/dataTable/filters/MultiSelectFilters";
  import Actions from "@/components/common/dataTable/users/Actions";
  import DateRangeFilter from "@/components/common/dataTable/filters/DateRangeFilter";
  import Confirmation from '../components/common/Confirmation'
  import AddClient from "@/components/people/actions/AddClient.vue";
  import AddNewClient from "@/components/people/client/AddNewClient.vue";

  export default {
    name: "Clients",
    components: {
      AddNewClient,
      AddClient,
      DataTable,
      Filters,
      MultiSelectFilters,
      DateRangeFilter,
      Actions,
      Confirmation
    },
    data() {
      return {
        apiurl: 'clients' ,
        search:{
          placeholder:  'Search by name,email,status,issues,treatments..',
          searchAble: true
        },
        sortOrder: [
          {
            field: 'created_at',
            sortField: 'created_at',
            direction: 'desc'
          }
        ],
        filters:[],
        selected_filters:[],
        loaded: false,
        detailUrl: 'client/details/',
        header: true,
        action:{}, //action on the table
        deleteDialog: false,
        clientID: '',
        showAddNewClientDialog: false,
        organisations: [],
        plans: [],
        creatingClient: false
      }
    },
    created() {
      this.fetchFilters();
      this.fetchClientCreationData();
    },
    methods:{
      handleAddNewClient(data){
        let loader = this.$loading.show()
        this.$http.put(this.url+'client/create', data).then((res) => {

            this.$router.push(this.detailUrl + res.data.id)
        }).catch((error) => {
          if(error.response && error.response.status === 400){
            this.$toastr('error', error.response.data.message, '')
          } else{
            this.$toastr('error', 'Something went wrong.', '')
          }
        }).finally(() => {
          loader.hide();
        })
      },
      fetchFilters(){
        this.$http.get(this.url+'filters/clients').then((res) => {
          if(res.data) {
            this.filters = res.data;
            this.loaded = true;
          } else {
            alert('there is some problem');
          }
        });
      },
      fetchClientCreationData(){
        this.$http.get(this.url+'client/create').then((res) => {
          if(res.data) {
            this.organisations = res.data.orgs.map(org => ({ org_id: org.id, name: org.name}))
            this.plans = res.data.plans.map(plan => ({plan_id: plan.id, name: plan.title, plan_type: plan.plan_type}))
          } else {
            alert('there is some problem');
          }
        });
      },
      fetchPlans(){

      },
      deleteConfirmation(member_id) {
        this.deleteDialog = true;
        this.clientID = member_id;
      },
      hideConfirmation() {
        this.deleteDialog = false;
      },
      removeClient() {
        let loader = this.$loading.show();
        this.$http.delete(this.url+'user/delete', {
          params: {
            user_id: this.clientID,
            value: 'deleted'
          }
        }).then(response => {
          this.hideConfirmation();
          this.$events.fire('vuetable:reload');
          loader.hide();
          this.$toastr('success',response.data.message,'');
        }).catch(error => {
          loader.hide();
          this.$toastr('error', error.response.data.message,'');
        });
      }
    },
    events: {
      'open-details': function(data) {
          this.$router.push(this.detailUrl + data.id);
      },
      'delete-record': function(member) {
        this.deleteConfirmation(member.id);
      },
      'get-table-ref': function(){
          const selectedIds = this.$refs.datatable.$refs.vuetable.selectedTo;
          this.$events.fire('get-selected-data',selectedIds);
      }
    },
  }
</script>
